import React from "react"
import { Link } from "gatsby"
import "./hero.css"

import pause from "../../images/pause.svg"
import play from "../../images/play.svg"

import Vimeo from "@vimeo/player"

import sanitizeHtml from "sanitize-html"


export default class Hero extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      render: "",
      paused: true,
    }    

    this.htmlOptions = {
        allowedTags: [ 'b', 'i', 'em', 'strong', 'a' ]
    }

  }

  setRender = () => {
    if (window.innerWidth < 1000){
      this.setState({
        render: "mobile"
      })
    }
      else{
        this.setState({
          render: "desktop"
        })
      }
    
  }

  componentDidMount(){
    this.setRender()
    document.addEventListener("resize", this.setRender)
  }

  componentWillUnmount(){
    document.removeEventListener("resize", this.setRender )
  }

  togglePlay = (event) => {
    if(event.key !== "Tab"){
    if (this.state.paused === true) {
      this.setState({
        paused : false 
      })
      this.toggleVideo(event,false)
    }
    else{
      this.setState({
        paused: true 
      })
      this.toggleVideo(event,true)
    }
    }
  }

  toggleVideo = (event,pause) => {
    var element = event.target.parentElement
    var iframe = element.querySelector('iframe');
    if ( iframe ) {
      var player = new Vimeo(iframe);
      pause ? player.play() : player.pause()

    }
    
   }

  render() {

    var desktopView = <img
    className="desktopHeroBackground"
    src={this.props.desktopHeroBackground}
    alt={this.props.alt}
  ></img>
   
    var mobileView = <img
    className="mobileHeroBackground"
    src={this.props.mobileHeroBackground}
    alt={this.props.alt}
  ></img>

    var iframeView

    if (this.state.render === "desktop" && this.props.video){
      iframeView = this.props.desktopIframe
    }
    else if(this.state.render === "mobile" && this.props.video){
      iframeView = this.props.mobileIframe
    }

    if(this.state.render == "desktop"){
      var backgroundImage = desktopView
    }
    else{
      var backgroundImage = mobileView
    }
    
    
    return (
    <div className="hero-outer">
        <div className={["row hero " + this.props.additionalClassName]}>
        {this.props.video ? (
          <div>
            {iframeView}

          
            {this.props.video && this.state.paused? null : <img alt="play button" className="play-hero" tabIndex="0" onKeyDown={this.togglePlay} onClick={this.togglePlay} src={play}></img>  }
            {this.props.video && !this.state.paused? null : <img alt="pause button" className="pause-hero" tabIndex="0" onKeyDown={this.togglePlay} onClick={this.togglePlay} src={pause}></img>}  

          </div>
        ) : (
          <div>
            {backgroundImage}
          </div>
        )}
        <div className={"hero-content " + ((this.props.customColumnClass!="") ? this.props.customColumnClass : "col-md-5") }>
          {(this.props.headline && (
            <h1 
              className={"animationA " + this.props.textGradient}
              dangerouslySetInnerHTML={{__html: sanitizeHtml(this.props.headline, this.htmlOptions)}}
            >
            </h1>
          )) ||
            null}
          {(this.props.headlineH2 && (
            <h2 className={"animationA carousel-header " + this.props.textGradient}>
              {this.props.headlineH2}
            </h2>
          )) ||
            null}

          {this.props.content
            ? <h2
                className="animationB"
                dangerouslySetInnerHTML={{__html: sanitizeHtml(this.props.content, this.htmlOptions)}}
              >
              </h2>

            : null}
        </div>
      </div>
    </div>
    )
  }
}

Hero.defaultProps = {
  customColumnClass: "",
  video: false,
}
