import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero/hero-html"
import RightCta from "../../components/cta/right-bordered/cta"

import "../../styles/diabetes-and-heart-disease.scss"

import desktopHero from "../../images/diabetes-and-heart-disease/1.4_1440x540_Desktop_Hero_1X.png"
import mobileHero from "../../images/diabetes-and-heart-disease/1.4_543x800_Mobile_Hero_2X.png"
import clipboard from "../../images/diabetes-and-heart-disease/1.2_Clipboard_Icon.svg"
import heart from "../../images/diabetes-and-heart-disease/1.2_Heart_Icon.svg"
import gradientOne from "../../images/diabetes-and-heart-disease/gradientOne.png"
import gradientTwo from "../../images/diabetes-and-heart-disease/gradientTwo.png"
import doctor from "../../images/diabetes-and-heart-disease/doctor-heart.png"
import doctorMobile from "../../images/diabetes-and-heart-disease/doctorMobile.png"
import ctaBackground from "../../images/diabetes-and-heart-disease/ctaBackground.png"
import withDoctor from "../../images/diabetes-and-heart-disease/doctor-talk.png"


export default () => (
  <Layout customClass="diabetes-and-heart-disease">
    <SEO
      title="Diabetes & Heart Disease Risk | VASCEPA® (icosapent ethyl)"
      keywords="diabetes and heart disease, diabetes and heart disease risk, VASCEPA and diabetes"
      description="Adults with diabetes are 2x more likely to have a heart attack or stroke. Learn how VASCEPA® (icosapent ethyl) when added to a statin reduced CV risk in adults with heart disease or diabetes with other risk factors. Please see
      Indications and Important Safety Information."
       />
      <Hero
        desktopHeroBackground={desktopHero}
        mobileHeroBackground={mobileHero}
        alt="Man smiling"
        headline="Let’s talk about <br/>diabetes, heart disease, <br/>and CV risk reduction."

        content="<strong>Did you know adults with diabetes are 2x more likely to have a heart attack or stroke?</strong> If you have diabetes or heart disease, read on to see how VASCEPA may help protect your heart."
                        
        additionalClassName = "height640"
        customColumnClass="col-lg-5 col-xl-6"
        />


    {/* BLOCK 1 */}
    <div className="block row flex-column-reverse flex-md-row">

      <div className="col-md-7 py-3 block-content center-items whiteborderRight">
        {<img className="background-gradient full" src={gradientOne}></img>}
        <div className="">
          <h3 className="font-margins">Managing diabetes & <br/>cardiovascular (CV) risk.</h3>
          <p className="font-margins over-blue">First things first. VASCEPA is not a diabetes medication. It's very important to continue taking your medication as directed by your doctor.</p>
          <p className="font-margins over-blue">VASCEPA is an <span className="nowrap">FDA-approved</span>, prescription medicine that can give your heart added protection when taken with a statin.</p>
          <p className="font-margins over-blue">VASCEPA can help manage your CV risk if you have diabetes or heart disease. That risk can be persistent and often elevated, especially since you may not realize just how at risk you may be.</p>
          <p className="font-margins over-blue">In a recent survey of people age 45 and older with type 2 diabetes, only half recognize or have discussed their risk for heart attacks or strokes with their healthcare providers.</p>  
        </div>
      </div>

      <div className="col-md-5 d-flex align-items-center py-5 py-md-0 boxed-image-container gray-on-mobile bottom-margin-mobile">
        <img width="218" src={clipboard} className="desktop-height-auto boxed-image position-static position-md-absolute" alt="Clipboard Illustration"></img>
      </div>

    </div>


    {/* BLOCK 2 */}
    <div className="block row">

      <div className="col-md-5 pt-md-0 block-image whiteborderRight gray-on-mobile bottom-margin-mobile">
        <img src={doctor} className="desktop-height-auto boxed-image doctor-diabetes d-none d-lg-block" alt="Doctor image"></img>
        <img src={doctorMobile} className="desktop-height-auto boxed-image d-block d-lg-none position-absolute position-md-absolute img-autowidth" alt="Doctor holding a clear VASCEPA® (icosapent ethyl) heart"></img>
      </div>

      <div className="col-md-7 block-content center-items py-3">
        <div className="">
          <h3 className="font-margins blue">There’s no such thing as too much heart protection.</h3>
          <p className="font-margins blue">The question for your doctor shouldn’t be “do I have enough heart protection?” It should be “how can I get additional heart protection?”</p>
          <p className="font-margins blue"><strong>That’s where VASCEPA can help. If you have heart disease or diabetes and raised triglycerides (≥150 mg/dL), VASCEPA can help lower your chance of a <span className="nowrap">life-threatening</span> heart attack or stroke by 25% when added to a statin.*</strong></p>
          <p className="font-margins gray indent small mt-0">*In a clinical study, patients treated with VASCEPA and a statin had fewer cardiovascular events (17.2%) compared to those who took placebo (22%).</p>
        </div>
      </div>

    </div>

    {/* BLOCK 3 */}
    <div className="block row flex-column-reverse flex-md-row">

      <div className="col-md-7 py-3 block-content center-items whiteborderRight">
        {<img className="background-gradient full" src={gradientTwo}></img>}
        <div className="">
          <h3 className="font-margins">An “A” from the ADA!</h3>
          <p className="font-margins over-blue">In <em>Standards of Medical Care in Diabetes</em> for 2022, the American Diabetes Association (ADA) gave icosapent ethyl, marketed as VASCEPA, an "A" recommendation.</p>
          <p className="font-margins over-blue">What does that mean? Generally, the ADA felt that the large clinical trial conducted with VASCEPA was well-designed and produced results that should be considered by physicians treating those with diabetes and cardiovascular (CV) disease or other CV risk factors on a statin with controlled bad cholesterol and elevated triglyceride levels.</p>
          <p className="font-margins over-blue">In adults with diabetes and other risk factors, VASCEPA can make a heart health difference when added to a statin.</p>
          <p className="font-margins over-blue small">The “A” recommendation from ADA was based on the quality of the REDUCE-IT<sup>®</sup> trial and is not for comparison to other diabetes products.</p>  
        </div>
      </div>

      <div className="col-md-5 d-flex align-items-center boxed-image-container gray-on-mobile bottom-margin-mobile">
        <img width="218" src={heart} className="desktop-height-auto boxed-image" alt="Heart Illustration"></img>
      </div>

    </div>

    <RightCta
      ctaBackground={ctaBackground}
      backgroundColor="#e7e8ea"
      colOne="7"
      colTwo="5"
      image={withDoctor}
      mobileImage={withDoctor}
      alt="talk to doctor icon"
      headline="Ready to ask your doctor about VASCEPA? This guide can help you start the conversation."
      contentHeadline="fix-padding-2"
      cta={["",<span>Download discussion </span>, <span aria-hidden="true">guide&nbsp;&gt;</span>]}
      headlineColor="#1D355E"
      ctaColor="#1D355E"
      url="https://amarincorp.com/docs/Vascepa-Downloadable-DDG-2020.pdf"
      external="true"
      containerClass="talk-img"
    />

    <div className="chatbot-spacer"></div>



  </Layout>
)
